import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import { PageMetaDataPropTypes } from 'types';

import { Layout } from 'components/Layout/Layout';
import { News, NewsPropTypes } from 'components/News/News';

const NewsPage = ({
  data: {
    markdownRemark: {
      frontmatter: { pageDescription, pageKeywords, pageTitle, ...rest },
      // eslint-disable-next-line react/prop-types
      html,
    },
  },
}) => (
  <Layout
    description={pageDescription}
    keywords={pageKeywords}
    title={pageTitle}
  >
    <News content={html} isHTML {...rest} />
  </Layout>
);

NewsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        ...PageMetaDataPropTypes,
        ...NewsPropTypes,
      }),
      html: PropTypes.node.isRequired,
    }),
  }).isRequired,
};

export default NewsPage;

export const newsPageQuery = graphql`
  query NewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      ...PageMetaDataFragment
      ...NewsPageFragment
    }
  }
`;
